<template>
  <template-base>
    <form>
      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Meta mensal de número de materiais processados"
          >
            <b-form-input
              type="number"
              v-model="model.numeroDeMateriaisProcessadosMensal"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Limite de perdas mensal (controle de BNP)"
          >
            <b-form-input
              type="number"
              v-model="model.limiteDePerdasMensal"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Meta mensal de número de pacotes por ciclo"
          >
            <b-form-input
              type="number"
              v-model="model.numeroDePacotesPorCicloMensal"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Limite mensal de reprocessamento de materiais"
          >
            <b-form-input
              type="number"
              v-model="model.limiteDeReprocessamentoDeMateriaisMensal"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Meta semestral de número de materiais processados"
          >
            <b-form-input
              type="number"
              v-model="model.numeroDeMateriaisProcessadosSemestral"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Limite de perdas semestral (controle de BNP)"
          >
            <b-form-input
              type="number"
              v-model="model.limiteDePerdasSemestral"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Meta semestral de número de pacotes por ciclo"
          >
            <b-form-input
              type="number"
              v-model="model.numeroDePacotesPorCicloSemestral"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group
            label="Limite semestral de reprocessamento de materiais"
          >
            <b-form-input
              type="number"
              v-model="model.limiteDeReprocessamentoDeMateriaisSemestral"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row align-h="between" class="pt-4">
        <b-col>
          <Button
            variant="grayscale"
            @click="cancel"
            text="Cancelar"
            :disabled="isBusy"
          />
        </b-col>
        <b-col class="text-right">
          <Button
            :disabled="isBusy"
            @click="onSubmit"
            text="Salvar"
          />
        </b-col>
      </b-row>
    </form>

    <b-overlay
      no-wrap
      :show="isBusy"
      variant="light"
      opacity="0.42"
      rounded="lg"
      spinner-small
    >
      <template #overlay>
        <b-spinner
          class="spinner-save"
        />
      </template>
    </b-overlay>
  </template-base>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import genericRequest from '@/services/genericRequest';
import Button from '@/components/Utils/Button';
import TemplateBase from '@/templates/Base';

export default {
  name: 'ConfigPage',

  components: {
    Button,
    TemplateBase,
  },

  data() {
    return {
      model: {},
      previousRoute: 'home',
      isBusy: false,
    };
  },

  computed: {
    ...mapState(['metas', 'currentUnidade']),
  },

  watch: {
    currentUnidade: {
      deep: true,
      handler() {
        this.updateModel();
      },
    },

    metas: {
      deep: true,
      handler() {
        this.updateModel();
      },
    },
  },

  mounted() {
    this.updateModel();
  },

  methods: {
    ...mapMutations(['setMetasUnidade']),

    updateModel() {
      this.model = { ...this.metas[this.currentUnidade.id_unidade] };
    },

    onSubmit() {
      if (this.isBusy) return;

      this.$validator.resume();

      this.$validator.validateAll().then(async (result) => {
        if (!result || this.isBusy) {
          return;
        }

        this.isBusy = true;

        this.update();
      });
    },

    cancel() {
      swal({
        title: 'Cancelamento de formulário',
        text: 'Deseja cancelar a edição das metas?',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      }).then((value) => {
        if (value) {
          this.$router.push({ name: this.previousRoute });
        }
      });
    },

    async update() {
      try {
        await genericRequest.update(null, { ...this.model, id_unidade: this.currentUnidade.id_unidade }, 'meta');

        this.setMetasUnidade({ idUnidade: this.currentUnidade.id_unidade, metas: this.model });

        await swal({
          title: 'Bom trabalho!',
          text: 'As configurações foram atualizadas com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });
      } catch (error) {
        console.log(error);
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Oops!',
          text:
            errorMessage || 'Ocorreu um problema ao tentar atualizar as configurações.',
          icon: 'error',
          button: 'Continuar',
        });
      } finally {
        this.isBusy = false;
      }
    },

    validateState(ref) {
      if (
        this.$parent.veeFields[ref]
        && document.getElementsByName(ref)[0]
        && !document.getElementsByName(ref)[0].disabled
        && (this.$parent.veeFields[ref].dirty || this.$parent.veeFields[ref].validated)
      ) {
        return !this.$parent.veeErrors.has(ref);
      }

      return null;
    },
  },
};
</script>
